

import retrace from 'retrace'

(function ($) {
  $.fn.jsErrorHandler = function (options) {

    var settings = {
      url:"/javascript_exception"
    };
    if (options) $.extend(settings, options);

    var ignoredStrings = ['conduitPage', 'instActive', 'Unexpected token C'];

    window.onerror = async function (msg, url, line, col, error) {
      var sendReport = true;
      if(!url.match(/application\-all/)){
        sendReport = false;
      }

      $.each(ignoredStrings, function (ignored) {
        if (msg.match(new RegExp(ignored))) {
          sendReport = false;
        }
      });

      if (sendReport) {
        var stack = error && error.stack;
        if (!stack) {
          stack = msg + '\n    at ' + url + ':' + line + ':' + col;
        }

        var result = await retrace.map(stack).then(function(stack) {
          return stack;
        });

        $.ajax({
          type:"GET",
          cache:false,
          url:settings.url,
          data:$.param({
            'message':msg,
            'url':url,
            'location':window.location.href,
            'userAgent':navigator.userAgent,
            'line':line,
            'browser':$.browser,
            'stack':result}),
          success:function (test) {
            if (window.console) console.log("Report sent about the javascript error.")
          }
        });
      }
      return true;
    }
  };
})(jQuery);

$(document).jsErrorHandler();


